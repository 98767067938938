<template>
	<div>
	
		<div class="content content_top_margin">
			<div class="content_inner  ">
				<div class="full_width ">
					<div class="full_width_inner">

						<div class="vc_row wpb_row section vc_row-fluid get-asst-bgimg"
							style=' text-align:left;'>
							<div class=" full_section_inner clearfix">
								<div class="wpb_column vc_column_container vc_col-sm-6 vc_col-has-fill">
									<div class="vc_column-inner get-asst-whitebg">
										<div class="wpb_wrapper">
											<div class="wpb_text_column wpb_content_element ">
												<div class="wpb_wrapper">
													<h1>Schedule your ergonomic assessment</h1>
													<p><em>Our ergonomist are here to help you feel (and work) your
															best.</em> Once you fill out the form, we will contact you
														shortly to schedule your appointment! We look forward to working
														with you and getting you comfy in your neighborhood!</p>
													<p>&nbsp;</p>
													<p>
														<iframe style="background-color: transparent;" 
														:src="company_data.formlink_schedule_request+'?firstname='+this.user_data.first_name+'&lastname='+this.lastName+'&email='+this.user_data.email" width="100%" height="500px" frameborder="0"></iframe>
														<!-- <iframe style="background-color: transparent;" src="https://pbergo.sjc1.qualtrics.com/jfe/form/SV_dbArOtclCrE8iwd" width="100%" height="500px" frameborder="0"></iframe> -->
														<!-- <iframe :src="company_data.formlink_schedule_request" frameborder="0"></iframe> -->
													</p>

												</div>
											</div>
											<div class="vc_empty_space" style="height: 50px"><span
													class="vc_empty_space_inner">
													<span class="empty_space_image"></span>
												</span></div>

										</div>
									</div>
								</div>
								<div class="wpb_column vc_column_container vc_col-sm-6">
									<div class="vc_column-inner">
										<div class="wpb_wrapper"></div>
									</div>
								</div>
							</div>
						</div>



					</div>
				</div>
			

			</div>
		</div>

	</div>
</template>
<script>
	//import carousel from "vue-owl-carousel"
	export default {
		name: 'schedule-evaluation-a45',
		data() {
        return {
            company_data: this.$store.getters["auth/authUser"].client ?? null,
			user_data:[], 
			lastName:"",
        };
    },
		mounted(){
			document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
        // this.company_data = JSON.parse(localStorage.getItem("companyInformation")); 
		let data = this.$store.getters["auth/authUser"];
		this.company_data = data.client;
		this.user_data = data.user;
		this.lastName = this.user_data.last_name ? this.user_data.last_name : "";
      },
		methods: {
			hideModal() {
				// this.$emit('hide');
				document.getElementById("closed").src += "";
			},
		}
	}
</script>